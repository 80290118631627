import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DataTable from 'components/DataTable/DataTable';
import Loader from 'components/Loader/Loader';
import CreateDiagnosticAssignmentUserModal from 'components/Modals/CreateDiagnosticAssignmentUserModal/CreateDiagnosticAssignmentUserModal';
import DeleteUserModal from 'components/Modals/DeleteUserModal/DeleteUserModal';
import PageTitle from 'components/PageTitle/PageTitle';
import Status from 'components/Status/Status';
import { Tag } from 'components/Tag/Tag';

import AssignedToCell from 'pages/Assessments/AssessmentList/components/Table/components/AssignedToCell/AssignedToCell';

import { useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import useTableControl from 'hooks/useTableControl';

import { useGetDiagnosticAssignmentStatusesQuery } from 'store/api/assessmentApi/assessmentApi';
import { useGetUserByIdQuery } from 'store/api/usersApi/usersApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { formatDateFull } from 'helpers/date';

import { MOBILE_BREAKPOINT } from 'constants/';

import { DeleteType } from 'types/enums';
import { ButtonFill, ButtonSize } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import { getUserOrganizationValue } from '../UserList/UserList';

import styles from './UserProfile.module.scss';

const UserProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const { page, size, onPageChange } = useTableControl('user_full_name');
  const [deleteType, changeDeleteType] = useState<DeleteType>(DeleteType.Soft);
  const { assessment, other_users_dashboard_comparison } = useAppSelector((state) => state.permissions.permissions);

  const canOpenDiagnosticAssignmentDetails = checkPermission(other_users_dashboard_comparison, [
    PermissionsAction.READ,
  ]);
  const { data: userDetails, isLoading, isError } = useGetUserByIdQuery(id);

  const {
    data: dataStatuses,
    isLoading: isLoadingStatuses,
    isFetching: isFetchingStatuses,
  } = useGetDiagnosticAssignmentStatusesQuery(
    {
      page,
      size,

      userId: id,
    },
    { skip: !userDetails },
  );

  const [openDeleteModal, toggleDeleteModal] = useState(false);
  const [openAssignDiagnosticVersionModal, toggleAssignDiagnosticVersionModal] = useState(false);

  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const userFullName = `${userDetails?.first_name} ${userDetails?.last_name}`;

  const handleCloseDeleteModal = () => toggleDeleteModal(false);

  const handleTryDemoClick = () => {
    toggleAssignDiagnosticVersionModal(true);
  };

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.USER_LIST);
    }
  }, [isError, navigate]);

  useDocumentTitle([userFullName || '']);

  const getPageTitleAction = () => {
    const actions = [];

    if (checkPermission(assessment, [PermissionsAction.DELETE])) {
      actions.push(
        <ButtonStyled
          key="delete"
          fill={ButtonFill.TransparentRed}
          size={ButtonSize.Link}
          onClick={() => toggleDeleteModal(true)}
          icon={<Delete />}
          justifyStart={isMobile}
        >
          {t('general.delete')}
        </ButtonStyled>,
      );
    }

    if (!isMobile && checkPermission(assessment, [PermissionsAction.UPDATE])) {
      actions.push(
        <ButtonStyled
          key="edit"
          onClick={() => {
            navigate(ROUTES.USER_EDIT(id));
          }}
          fill={ButtonFill.Outlined}
          size={ButtonSize.Link}
          icon={<Edit />}
        >
          {t('general.edit')}
        </ButtonStyled>,
      );
    }

    if (!isMobile && checkPermission(assessment, [PermissionsAction.ASSIGN])) {
      actions.push(
        <ButtonStyled size={ButtonSize.Small} fill={ButtonFill.Contained} onClick={handleTryDemoClick}>
          {t('assessment.send_assignment')}
        </ButtonStyled>,
      );
    }

    return actions;
  };

  const pageTitleActions = getPageTitleAction();

  type Row = {
    id: string;
  };

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'windowedAssignmentVersionName',
        headerName: t('assessment.internal_name'),
        sortable: true,
        flex: 1,
      },
      {
        field: 'groupsNames',
        headerName: t('general.group'),
        sortable: false,
        valueFormatter: (data) => data.value[0] || t('general.not_assigned'),
        renderCell: (params) => <AssignedToCell value={params.value} formattedValue={params.formattedValue} />,
        flex: 1,
      },
      {
        field: 'status',
        headerName: t('inputs.status'),
        width: 200,
        sortable: true,
        renderCell: (params: GridRenderCellParams<any, Row>) => <Status inline value={params.row.status as string} />,
      },
      {
        field: 'createdAt',
        headerName: t('assessment.diagnostic_versions_table.created_date'),
        width: 150,
        sortable: true,
        valueFormatter: (data) => formatDateFull(data.value) || ' - ',
      },
    ],

    [t],
  );

  const rows = dataStatuses
    ? dataStatuses.items.map((item) => ({
        id: item.id,
        status: item.status,
        createdAt: item.created_at,
        updatedAt: item.updated_at,
        userId: item.user_id,
        userFullName: item.user_full_name,
        groupId: item.group_id,
        groupName: item.group_name,
        windowedAssignmentVersionId: item.windowed_assignment_version_id,
        windowedAssignmentVersionName: item.windowed_assignment_version_name,
        assessmentId: item.assessment_id,
        groupsNames: item.group_accesses.map((item) => item.organization),
      }))
    : [];

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <Loader flexCenter size="md" />
      ) : (
        <>
          <CreateDiagnosticAssignmentUserModal
            open={openAssignDiagnosticVersionModal}
            handleClose={() => toggleAssignDiagnosticVersionModal(false)}
            userId={id}
          />

          <DeleteUserModal
            selectedUserId={id}
            deleteType={deleteType}
            open={openDeleteModal}
            close={handleCloseDeleteModal}
            change={changeDeleteType}
          />

          <Breadcrumb
            items={[
              { name: t('users.user_list'), href: ROUTES.USER_LIST },
              {
                name: userFullName || t('general.not_specified'),
                href: ROUTES.USER_PROFILE(id),
                active: true,
              },
            ]}
          />

          <PageTitle
            hideBorder
            title={
              <div className={styles.PageTitle}>
                <h3>{userFullName}</h3>
              </div>
            }
            titleTopColumn={isMobile}
          >
            {pageTitleActions.length ? pageTitleActions : null}
          </PageTitle>

          <div className={styles.TagsContainer}>
            <div className={styles.Header}>
              <h3>{t('users.user_details')}</h3>
            </div>
          </div>

          <div className={styles.AssessmentInfoContainer}>
            <div className={styles.AssessmentInfo}>
              <span>{t('users.user_organization')}</span>
              <p>
                {(userDetails?.group_accesses && getUserOrganizationValue(userDetails.group_accesses)) ||
                  t('general.not_specified')}
              </p>
            </div>

            <Box display="flex" justifyContent="space-between">
              <div className={styles.AssessmentInfo}>
                <span>{t('users.user_unit')}</span>
                <div className={styles.TagsContainer}>
                  <div style={{ width: 'fit-content' }}>
                    <Tag>{userDetails?.group_accesses[0].unit || t('general.not_specified')}</Tag>
                  </div>
                </div>
              </div>

              <div className={styles.AssessmentInfo}>
                <span>{t('users.user_role')}</span>
                <p>{userDetails?.group_accesses[0].role || t('general.not_specified')}</p>
              </div>
            </Box>
          </div>

          <div className={styles.TagsContainer}>
            <div className={styles.Header}>
              <h3>{t('assessment.table.assigned_to')}</h3>
            </div>

            <DataTable
              newDesign
              data={{ items: rows, total: dataStatuses?.total }}
              columns={columns}
              isLoading={isLoadingStatuses || isFetchingStatuses}
              page={page}
              changePage={onPageChange}
              emptyResultText={t('assessment.table.assignments_empty_result')}
              onRowClick={
                canOpenDiagnosticAssignmentDetails
                  ? ({ row }) => {
                      navigate(ROUTES.DIAGNOSTIC_ASSIGNMENT_DETAILS(row.windowedAssignmentVersionId));
                    }
                  : undefined
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UserProfile;
