import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, ClickAwayListener } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import truncate from 'lodash/truncate';

import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import DataTable from 'components/DataTable/DataTable';
import FilterByBlock from 'components/FilterByBlock/FilterByBlock';
import FilterByButton from 'components/FilterByButton/FilterByButton';
import Loader from 'components/Loader/Loader';
import DeleteDiagnosticAssignmentModal from 'components/Modals/DeleteDiagnosticAssignmentModal/DeleteDiagnosticAssignmentModal';
import DemoModal from 'components/Modals/DemoModal/DemoModal';
import NewSearchBar from 'components/NewSearchBar/NewSearchBar';
import NewSortButton from 'components/NewSortButton/NewSortButton';
import PageTitle from 'components/PageTitle/PageTitle';
import Status from 'components/Status/Status';
import { Tag } from 'components/Tag/Tag';

import AssignedToCell from '../AssessmentList/components/Table/components/AssignedToCell/AssignedToCell';

import { useAppSelector } from 'hooks/redux';
import { useDebounce } from 'hooks/useDebounce';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import useTableControl from 'hooks/useTableControl';

import {
  useDeleteDiagnosticAssignmentMutation,
  useGetDiagnosticAssignmentQuery,
  useGetDiagnosticAssignmentStatusesQuery,
} from 'store/api/assessmentApi/assessmentApi';
import { useSendDiagnosticAssignmentEmailMutation } from 'store/api/authApi/authApi';

import ROUTES from 'router/routes';

import { checkPermission } from 'helpers/checkUserPermission';
import { formatDateFull } from 'helpers/date';
import { formatDiagnosticAssignmentCompletionRate } from 'helpers/diagnostic';

import { MOBILE_BREAKPOINT } from 'constants/';

import { DiagnosticVersionStatus } from 'types/enums';
import { ButtonFill, ButtonSize } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';

import { ReactComponent as Announcement } from 'assets/images/announcement.svg';
import { ReactComponent as Delete } from 'assets/images/delete-icon.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';
import { ReactComponent as PlayIcon } from 'assets/images/play.svg';

import styles from './DiagnosticAssignmentDetails.module.scss';

const DiagnosticAssignmentDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const { page, size, sort, search, onPageChange, onSearching, onSorting } = useTableControl('user_full_name');

  const { assessment } = useAppSelector((state) => state.permissions.permissions);

  const debouncedSearch = useDebounce(search, 500);
  const [filterBy, setFilterBy] = useState<{
    status: DiagnosticVersionStatus | '';
    groupId: string;
  }>({
    status: '',
    groupId: '',
  });
  const { data: dataAssignment, isLoading, isError } = useGetDiagnosticAssignmentQuery(id);
  const {
    data: dataStatuses,
    isLoading: isLoadingStatuses,
    isFetching: isFetchingStatuses,
  } = useGetDiagnosticAssignmentStatusesQuery(
    {
      id,
      diagnosticId: dataAssignment?.assessment_id!,
      page,
      size,
      search: debouncedSearch,
      orderBy: sort.sortBy,
      ...filterBy,
    },
    { skip: !dataAssignment },
  );

  const [openDeleteModal, toggleDeleteModal] = useState(false);

  const [openDemoModal, toggleDemoModal] = useState(false);
  const [showSortByBlock, setShowSortByBlock] = useState(false);
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const handleCloseDeleteModal = () => toggleDeleteModal(false);

  const handleCloseDemoModal = () => toggleDemoModal(false);

  const [deleteDiagnosticAssignment, { isLoading: isLoadingDeleteDiagnosticAssignment }] =
    useDeleteDiagnosticAssignmentMutation();
  const [
    sendDiagnosticAssignmentEmail,
    { isLoading: isLoadingAssignmentEmail, originalArgs: originalArgsAssignmentEmail },
  ] = useSendDiagnosticAssignmentEmailMutation();

  const handleDelete = () => {
    if (id) {
      deleteDiagnosticAssignment(id)
        .unwrap()
        .then(() => {
          handleCloseDeleteModal();
          navigate(ROUTES.DIAGNOSTIC_LIST);
          toast.success(t('assessment.delete_assignment_success'));
        })
        .catch(() => {
          toast.error(t('general.error'));
        });
    }
  };

  const handleTryDemoClick = () => {
    toggleDemoModal(true);
  };

  const handleOpenDemo = () => {
    if (dataAssignment) {
      navigate(`${ROUTES.DIAGNOSTIC_TAKE(dataAssignment.assessment_id, id)}?demo=true`);
    }
  };

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.DIAGNOSTIC_LIST);
    }
  }, [isError, navigate]);

  useDocumentTitle([dataAssignment?.version_name || '']);

  const getPageTitleAction = () => {
    const actions = [];

    if (checkPermission(assessment, [PermissionsAction.DELETE])) {
      actions.push(
        <ButtonStyled
          key="delete"
          fill={ButtonFill.TransparentRed}
          size={ButtonSize.Link}
          onClick={() => toggleDeleteModal(true)}
          icon={<Delete />}
          justifyStart={isMobile}
        >
          {t('general.delete')}
        </ButtonStyled>,
      );
    }

    if (!isMobile && checkPermission(assessment, [PermissionsAction.UPDATE])) {
      actions.push(
        <ButtonStyled
          key="edit"
          onClick={() => {
            navigate(ROUTES.DIAGNOSTIC_ASSIGNMENT_EDIT(id));
          }}
          fill={ButtonFill.Outlined}
          size={ButtonSize.Link}
          icon={<Edit />}
        >
          {t('general.edit')}
        </ButtonStyled>,
      );
    }

    actions.push(
      <ButtonStyled
        size={ButtonSize.Small}
        fill={ButtonFill.Contained}
        onClick={handleTryDemoClick}
        icon={<PlayIcon />}
      >
        {t('assessment.try_demo')}
      </ButtonStyled>,
    );

    return actions;
  };

  const pageTitleActions = getPageTitleAction();
  // const { page, size, onPageChange, onSearching } = useTableControl('user');

  type Row = {
    id: string;
  };

  const getActions = useCallback(
    (userId: string) => {
      const actions: ReactNode[] = [];
      if (checkPermission(assessment, [PermissionsAction.ASSIGN])) {
        actions.push(
          <ButtonStyled
            key="remind"
            onClick={() => {
              sendDiagnosticAssignmentEmail({
                assigneeIds: [userId],
                assignmentVersionName: dataAssignment?.version_name || '',
              })
                .unwrap()
                .then(() => {
                  toast.success(t('assessment.remind_success'));
                })
                .catch((error) => {
                  toast.error(error?.data?.detail || t('general.error'));
                });
            }}
            fill={ButtonFill.TransparentBold}
            size={ButtonSize.Bold}
            loading={isLoadingAssignmentEmail && userId === originalArgsAssignmentEmail?.assigneeIds[0]}
            icon={<Announcement />}
          >
            {t('general.remind')}
          </ButtonStyled>,
        );
      }
      return actions;
    },
    [
      assessment,
      dataAssignment?.version_name,
      isLoadingAssignmentEmail,
      originalArgsAssignmentEmail?.assigneeIds,
      sendDiagnosticAssignmentEmail,
      t,
    ],
  );

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'userFullName',
        headerName: t('assessment.user'),
        sortable: false,
        flex: 1,
      },
      {
        field: 'groupsNames',
        headerName: t('general.group'),
        sortable: false,
        valueFormatter: (data) => data.value[0] || t('general.not_assigned'),
        renderCell: (params) => <AssignedToCell value={params.value} formattedValue={params.formattedValue} />,
        flex: 1,
      },
      {
        field: 'status',
        headerName: t('inputs.status'),
        width: 200,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, Row>) => <Status inline value={params.row.status as string} />,
      },
      {
        field: 'createdDate',
        headerName: t('assessment.diagnostic_versions_table.created_date'),
        width: 150,
        sortable: false,
        valueFormatter: (data) => formatDateFull(data.value) || ' - ',
      },
      {
        field: 'actions',
        headerName: '',
        align: 'right',
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, Row>) => getActions(params.row.userId),
      },
    ],

    [getActions, t],
  );

  const rows = dataStatuses
    ? dataStatuses.items.map((item) => ({
        id: item.id,
        status: item.status,
        createdDate: item.created_at,
        userId: item.user_id,
        userFullName: item.user_full_name,
        groupId: item.group_id,
        groupName: item.group_name,
        windowedAssignmentVersionId: item.windowed_assignment_version_id,
        assessmentId: item.assessment_id,
        groupsNames: item.group_accesses.map((group) => group.organization),
      }))
    : [];

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <Loader flexCenter size="md" />
      ) : (
        <>
          <DeleteDiagnosticAssignmentModal
            open={openDeleteModal}
            close={handleCloseDeleteModal}
            handleDelete={handleDelete}
            actionDisabled={isLoadingDeleteDiagnosticAssignment}
            status={isLoadingDeleteDiagnosticAssignment}
          />

          <DemoModal open={openDemoModal} handleClose={handleCloseDemoModal} handleOpenDemo={handleOpenDemo} />

          <Breadcrumb
            items={[
              { name: t('assessment.diagnostic'), href: ROUTES.DIAGNOSTIC_LIST },
              {
                name:
                  truncate(dataAssignment?.assessment_title, { length: 33, omission: '...' }) ||
                  t('general.not_specified'),
                href: ROUTES.DIAGNOSTIC_DETAILS(dataAssignment?.assessment_id),
              },
              {
                name:
                  truncate(dataAssignment?.version_name, { length: 33, omission: '...' }) || t('general.not_specified'),
                active: true,
              },
            ]}
          />

          <PageTitle
            hideBorder
            title={
              <div className={styles.PageTitle}>
                <h3>{dataAssignment?.version_name}</h3>
              </div>
            }
            titleTopColumn={isMobile}
          >
            {pageTitleActions.length ? pageTitleActions : null}
          </PageTitle>

          <div className={styles.TagsContainer}>
            <div className={styles.Header}>
              <h3>{t('assessment.main_info')}</h3>
            </div>
          </div>

          <div className={styles.AssessmentInfoContainer}>
            <div className={styles.AssessmentInfo}>
              <span>{t('assessment.external_name')}</span>
              <p>{dataAssignment?.assessment_title || t('general.not_specified')}</p>
            </div>

            <Box display="flex" justifyContent="space-between">
              <div className={styles.AssessmentInfo}>
                <span>{t('assessment.diagnostic_versions_table.creator')}</span>
                <p>{dataAssignment?.creator_full_name || t('general.not_specified')}</p>
              </div>

              <div className={styles.AssessmentInfo}>
                <span>{t('general.unit')}</span>
                <div className={styles.TagsContainer}>
                  <div style={{ width: 'fit-content' }}>
                    {dataAssignment?.assessment_unit_titles.length ? (
                      <AssignedToCell
                        value={dataAssignment?.assessment_unit_titles}
                        formattedValue={dataAssignment?.assessment_unit_titles[0]}
                      />
                    ) : (
                      <Tag>{t('general.not_specified')}</Tag>
                    )}
                  </div>
                </div>
              </div>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <div className={styles.AssessmentInfo}>
                <span>{t('assessment.diagnostic_versions_table.created_date')}</span>
                <p>{(dataAssignment && formatDateFull(dataAssignment.created_at)) || t('general.not_specified')}</p>
              </div>

              <div className={styles.AssessmentInfo}>
                <span>{t('assessment.diagnostic_versions_table.completion_rate')}</span>
                <p>
                  {(dataAssignment && formatDiagnosticAssignmentCompletionRate(dataAssignment.completion_rate)) ||
                    t('general.not_specified')}
                </p>
              </div>
            </Box>
          </div>

          <div className={styles.TagsContainer}>
            <div className={styles.Header}>
              <h3>{t('assessment.table.assigned_to')}</h3>
            </div>

            <Box display="flex" justifyContent="space-between" marginBottom={'16px'}>
              <div className={styles.SearchContainer}>
                <NewSearchBar small search={search} onSearch={onSearching} />
              </div>
              <ClickAwayListener onClickAway={() => setShowSortByBlock(false)}>
                <div className={styles.SortFilterByContainer}>
                  <NewSortButton sortType={sort.sortType} onSort={onSorting} />
                  <div className={styles.FilterByButtonContainer}>
                    <FilterByButton
                      show={showSortByBlock || Boolean(filterBy.status) || Boolean(filterBy.groupId)}
                      sortType={sort.sortType}
                      onSort={() => setShowSortByBlock((prev) => !prev)}
                    />
                    {showSortByBlock && (
                      <div className={styles.FilterByBlockContainer}>
                        <FilterByBlock
                          value={filterBy}
                          onFilter={({ status, groupId }) => {
                            setFilterBy({ status, groupId });
                            setShowSortByBlock(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </ClickAwayListener>
            </Box>

            <DataTable
              newDesign
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    actions: checkPermission(assessment, [PermissionsAction.ASSIGN]),
                  },
                },
              }}
              data={{ items: rows, total: dataStatuses?.total }}
              columns={columns}
              isLoading={isLoadingStatuses || isFetchingStatuses}
              page={page}
              changePage={onPageChange}
              emptyResultText={t('assessment.table.assignments_empty_result')}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DiagnosticAssignmentDetails;
